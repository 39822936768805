<template>
    <ActivitiesPreload v-if="state.preload" />
    <div v-else class="activity">

<!--  Competition    -->
        <!-- <div class="activity__row">
            <label >PBL</label>
            <div class="content">
                <div class="section">
                    <div class="section__title">Summary</div>
                    <div class="section__content">
                        <div class="section__content__item">
                            <div class="section__content__item__title">
                                進捗率
                            </div>
                            <div class="section__content__item__value blue">
                                {{ state.userProfileActivities.competition?.summary?.progress ?? 0 }} %
                            </div>
                        </div>
                        <div class="section__content__item">
                            <div class="section__content__item__title">
                                参加資格
                            </div>
                            <template v-if="state.userProfileActivities.competition?.summary?.progress == 100">
                              <div class="section__content__item__value blue">
                                あり
                              </div>
                            </template>
                            <template v-else>
                              <div class="section__content__item__value blue">
                                なし
                              </div>
                              <div>
                                進捗率を100%にしてください
                              </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
<!-- Learning -->
        <div class="activity__row">
            <label >Learning</label>
            <div class="content">
                <div class="section" v-if="state.userProfileActivities.learning?.summary?.assessments?.length > 0">
                  <div class="section__content">
                    <div class="section__content__item"
                         v-for="(assessment, index) in state.userProfileActivities.learning?.summary?.assessments"
                         :key="index"
                    >
                      <div class="section__content__item__title">
                        {{assessment.title}}
                      </div>
                      <div class="section__content__item__value blue">
                        {{ assessment.score ? state.transformUtil.formatNumber(assessment.score) : "-" }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section">
                    <div class="section__title">Summary</div>
                    <div class="section__content">
                        <div class="section__content__item">
                            <div class="section__content__item__title">クリア済み</div>
                            <div class="section__content__item__value blue">
                              {{ state.transformUtil.formatNumber(state.userProfileActivities.learning?.progress?.summary?.cleared_gyms_count
                                + state.userProfileActivities.learning?.progress?.summary?.cleared_quests_count) }}
                            </div>
                          Gym : {{ state.transformUtil.formatNumber(state.userProfileActivities.learning?.progress?.summary?.cleared_gyms_count) }} /
                          Quest : {{ state.transformUtil.formatNumber(state.userProfileActivities.learning?.progress?.summary?.cleared_quests_count) }}
                        </div>
                        <div class="section__content__item">
                            <div class="section__content__item__title">スコア</div>
                            <div class="section__content__item__value blue">
                              {{ state.userProfileActivities.learning?.progress?.summary?.exp ? state.transformUtil.formatNumber(state.userProfileActivities.learning?.progress?.summary?.exp) : "-" }}
                            </div>
                            Exp
                        </div>
                        <div class="section__content__item">
                            <div class="section__content__item__title">レベル</div>
                            <div class="section__content__item__value blue">
                              {{ state.userProfileActivities.learning?.progress?.summary?.level ? state.userProfileActivities.learning?.progress?.summary?.level : "-" }}
                            </div>
                          次のレベルまで {{ state.transformUtil.formatNumber(state.userProfileActivities.learning?.progress?.summary?.need_exp) }} Exp
                        </div>
                    </div>
                </div>

              <div class="section">
                <div class="section__title">Progress</div>
                <div class="section__content">
                  <table border>
                    <thead>
                    <tr>
                      <th>タイプ</th>
                      <th class="left">講座名</th>
                      <th>業種・タグ</th>
                      <th>進捗率</th>
                      <th>クリア日</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-if="state.userProfileActivities.learning?.progress?.quests?.length > 0" >
                      <tr
                          v-for="(result, index) in state.userProfileActivities.learning?.progress?.quests"
                          :key="index"
                      >
                        <td><span :class="getTypeIconClass(result.type)" /></td>
                        <td>{{ result.title }}</td>
                        <td>
                          <template
                              v-for="(business_type, index) in result.business_types"
                              :key="index"
                          >
                              {{ business_type.name }}
                          </template>
                          <template
                              v-for="(tag, index) in result.tags"
                              :key="index"
                          >
                            {{ tag.name }}
                          </template>
                        </td>
                        <td>
                          {{ result.progress_rate }} %
                        </td>
                        <td>
                          {{ formatDate(result.cleared_at) }}
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="7">表示するデータがありません。</td>
                      </tr>
                    </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        </div>

        <div class="activity__row">
            <label >Github</label>
            <div class="content" v-bind:class="{ 'no-data': !state.userProfileActivities.github }">
                <span v-if="!state.userProfileActivities.github">登録なし</span>
                <a target="_blank" v-bind:href="state.userProfileActivities.github" v-if="state.userProfileActivities.github">
                    {{ state.userProfileActivities.github }}
                </a>
            </div>
            <a
                href="javascript:void(0);"
                class="icon-edit"
                @click="openEditModal('github')"
                v-if="state.isOwner"
            >
                <i
                    class="fal fa-link"
                    aria-hidden="true"
                ></i>
            </a>
        </div>
        <div class="activity__row">
            <label >Qiita</label>
            <div class="content" v-bind:class="{ 'no-data': !state.userProfileActivities.qiita }">
                <span v-if="!state.userProfileActivities.qiita">登録なし</span>
                <a target="_blank" v-bind:href="state.userProfileActivities.qiita" v-if="state.userProfileActivities.qiita">
                    {{ state.userProfileActivities.qiita }}
                </a>
            </div>
            <a
                href="javascript:void(0);"
                class="icon-edit"
                @click="openEditModal('qiita')"
                v-if="state.isOwner"
            >
                <i
                    class="fal fa-link"
                    aria-hidden="true"
                ></i>
            </a>
        </div>
        <div class="activity__row">
            <label >Blog</label>
            <div class="content" v-bind:class="{ 'no-data': !state.blogPost || state.blogPost.length === 0 }">
                <span v-if="!state.blogPost || state.blogPost.length === 0">登録なし</span>
                <div
                    v-if="state.blogPost && state.blogPost.length > 0"
                    class="activity__embed__blog"
                    v-for="(blog, idx) in state.blogPost"
                    v-bind:key="idx"
                >
                    <a
                        target="_blank"
                        :href="blog.link"
                        class="activity__embed__blog__title">
                        {{ blog.title }}
                    </a>
                    <div class="activity__embed__blog__date">
                        {{ blog.pubDate }}
                    </div>
                    <div class="activity__embed__blog__content">
                        <a
                            target="_blank"
                            :href="blog.link"
                            class="blog__thumbnail"
                            v-if="blog.thumbnail"
                        >
                            <img
                                :src="blog.thumbnail"
                                :alt="blog.title"
                            >
                        </a>
                        <div class="blog__content">
                            {{ blog.description_text }}
                        </div>
                    </div>
                </div>
                <a
                    target="_blank"
                    :href="state.blogChannelInfo.link"
                    v-if="state.totalBlog && state.totalBlog > 3"
                    class="activity__embed__blog__read_more"
                >
                    続きを読む
                </a>
            </div>
            <a
                href="javascript:void(0);"
                class="icon-edit"
                @click="openEditModal('blog')"
                v-if="state.isOwner"
            >
                <i
                    class="fal fa-link"
                    aria-hidden="true"
                ></i>
            </a>
        </div>
        <div class="activity__row">
            <label >Youtube</label>
            <div class="content" v-bind:class="{ 'no-data': !state.userProfileActivities.youtube || state.userProfileActivities.youtube.length === 0 }">
                <span v-if="!state.userProfileActivities.youtube || state.userProfileActivities.youtube.length === 0">登録なし</span>
                <div
                    class="activity__embed__video_list"
                    v-if="state.userProfileActivities.youtube && state.userProfileActivities.youtube.length > 0"
                >
                    <div
                        class="activity__embed__video"
                        v-if="state.userProfileActivities.youtube && state.userProfileActivities.youtube.length > 0"
                        v-for="(url, idx) in state.userProfileActivities.youtube"
                    >
                        <a
                            v-if="state.youtubeEditMode && state.isOwner"
                            href="javascript:void(0);"
                            class="icon-edit youtube-update"
                            @click="updateYoutubeEmbed(idx)"
                        >
                            <i
                                class="fal fa-edit"
                                aria-hidden="true"
                            ></i>
                        </a>
                        <a
                            v-if="state.youtubeEditMode && state.isOwner"
                            href="javascript:void(0);"
                            class="icon-edit youtube-update"
                            @click="removeYoutubeEmbed(idx)"
                        >
                            <i
                                class="fal fa-minus-circle"
                                aria-hidden="true"
                            ></i>
                        </a>
                        <iframe
                            v-if="state.stringUtils.extractYoutubeId(url)"
                            :src="config.youtubeIfame + state.stringUtils.extractYoutubeId(url)"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                        <div v-else>
                          <a :href="url" target="_blank">
                            <img src="@/assets/images/common/youtube_icon.png" alt="" style="max-width: 100%; height: auto;"/>
                          </a>
                        </div>
                    </div>
                    <div
                        class="activity__embed__video add"
                        @click="openEditModal('youtube')"
                        v-if="state.isOwner"
                    >
                        追加する
                    </div>
                </div>
            </div>
            <a
                v-if="(!state.userProfileActivities.youtube || state.userProfileActivities.youtube.length === 0) && state.isOwner"
                href="javascript:void(0);"
                class="icon-edit"
                @click="openEditModal('youtube')"
            >
                <i
                    class="fal fa-edit"
                    aria-hidden="true"
                ></i>
            </a>
            <a
                v-if="(state.userProfileActivities.youtube && state.userProfileActivities.youtube.length > 0) && state.isOwner"
                href="javascript:void(0);"
                class="icon-edit"
                @click="state.youtubeEditMode = !state.youtubeEditMode"
            >
                <i
                    class="fal fa-edit"
                    aria-hidden="true"
                ></i>
            </a>
        </div>
    </div>

    <UserProfileEditActivitiesPopup
        v-if="state.showEditPopup"
        :field="state.editField"
        :youtubeCodeUpdate="state.youtubeCode"
        @close="closeEditModal()"
    />
</template>
<script lang="ts" src="@/presentation/views/user/profile/activity/Activity.ts" />
<link type="scss" scoped src="@/presentation/views/user/profile/activity/Activity.scss" />
