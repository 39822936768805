import { reactive, defineComponent, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import CommonService from '@/services/CommonService';
import StringUtils from "@/commons/StringUtils";
import TransformUtil from "@/commons/TransformUtil";
import ObjectUtil from "@/commons/ObjectUtil";
import UserProfileEditActivitiesPopup from "@/presentation/components/modals/user-profile-edit-activities/UserProfileEditActivities.vue";
import UserInteractor from "@/domain/usecases/UserInteractor";
import DIContainer from "@/core/DIContainer";
import ActivitiesPreload from "@/presentation/components/preloader/activities-preload/ActivitiesPreload.vue";
import MyConfig from '@/_config';
import Constant from '@/commons/Constant';
export default defineComponent({
    name: "UserProfileActivity",
    components: {
        UserProfileEditActivitiesPopup: UserProfileEditActivitiesPopup,
        ActivitiesPreload: ActivitiesPreload
    },
    props: ["breadcrumb", "alert"],
    setup: function (props) {
        var store = useStore();
        var router = useRouter();
        var config = MyConfig;
        var route = useRoute();
        var state = reactive({
            stringUtils: StringUtils,
            transformUtil: TransformUtil,
            preload: true,
            showEditPopup: false,
            editField: "",
            userProfileActivities: {},
            blogPost: [],
            blogChannelInfo: {},
            totalBlog: 0,
            youtubeEditMode: false,
            youtubeCode: "",
            isOwner: false,
            issetCompetitionSummary: false,
            issetCompetitionResult: false,
            issetLearning: false,
            issetKaggle: false
        });
        var userInteractor = DIContainer.instance.get(UserInteractor);
        var objectUtil = new ObjectUtil();
        function openEditModal(field) {
            state.showEditPopup = true;
            state.editField = field;
            state.youtubeCode = "";
        }
        function closeEditModal() {
            state.showEditPopup = false;
            getUserActivities();
        }
        function updateYoutubeEmbed(index) {
            state.editField = "youtube";
            state.youtubeCode = state.userProfileActivities.youtube[index];
            state.showEditPopup = true;
        }
        function removeYoutubeEmbed(index) {
            state.userProfileActivities.youtube.splice(index, 1);
            var formData = {
                youtube: state.userProfileActivities.youtube
            };
            return userInteractor
                .updateUserProfileActivities(formData)
                .then(function (result) {
                store.commit("setUserProfileActivitiesData", state.userProfileActivities);
            }, function (error) {
            });
        }
        function checkCompetitionSummary() {
            if (!state.userProfileActivities.competition) {
                return false;
            }
            if (!state.userProfileActivities.competition.summary) {
                return false;
            }
            if (objectUtil.isEmptyObject(state.userProfileActivities.competition.summary)) {
                return false;
            }
            return true;
        }
        function checkCompetitionResult() {
            if (!state.userProfileActivities.competition) {
                return false;
            }
            if (!state.userProfileActivities.competition.results) {
                return false;
            }
            if (!Array.isArray(state.userProfileActivities.competition.results)) {
                return false;
            }
            return true;
        }
        function checkLearning() {
            if (!state.userProfileActivities.learning) {
                return false;
            }
            if (!state.userProfileActivities.learning.summary) {
                return false;
            }
            if (objectUtil.isEmptyObject(state.userProfileActivities.learning.summary)) {
                return false;
            }
            return true;
        }
        function checkKaggle() {
            if (!state.userProfileActivities.kaggle) {
                return false;
            }
            if (objectUtil.isEmptyObject(state.userProfileActivities.kaggle)) {
                return false;
            }
            return true;
        }
        function getTypeIconClass(type) {
            switch (type) {
                case 'quest':
                    return 'fas fa-rocket';
                case 'gym':
                    return 'fas fa-satellite-dish';
                default:
                    return 'fa fa-ellipsis-h';
            }
        }
        function formatDate(date) {
            if (date) {
                var tmpDate = new Date(date);
                return tmpDate.getFullYear() + '/' + (tmpDate.getMonth() + 1) + '/' + tmpDate.getDate();
            }
            return '-';
        }
        function getUserActivities() {
            return userInteractor
                .getUserProfileActivities(Number(route.params.userId))
                .then(function (result) {
                state.preload = false;
                state.blogPost = [];
                if (result.data.blog) {
                    state.blogChannelInfo = result.data.blog.channel;
                    state.totalBlog = result.data.blog.channel.item.length;
                    if (result.data.blog.channel && Array.isArray(result.data.blog.channel.item)) {
                        state.blogPost = result.data.blog.channel.item;
                        state.blogPost.length = state.blogPost.length < 3 ? state.blogPost.length : 3;
                        state.blogPost.forEach(function (item) {
                            item.description = item.description.__cdata || item.description;
                            if (item.description && typeof (item.description) == "string") {
                                var dom = document.createElement('div');
                                dom.innerHTML = item.description;
                                item.description_text = StringUtils.trimLine(dom.innerText, 150);
                                if (dom.querySelector('img')) {
                                    item.thumbnail = dom.querySelector('img').src;
                                }
                            }
                        });
                    }
                }
                else {
                    state.totalBlog = 0;
                    state.blogChannelInfo = {};
                    state.blogPost = [];
                }
                store.commit('setUserProfileActivitiesData', result.data);
                state.userProfileActivities = result.data;
                state.userProfileActivities.feed_url = (state.userProfileActivities.blog && state.userProfileActivities.blog.feed_url) ? state.userProfileActivities.blog.feed_url : null;
            })
                .catch(function (error) {
                state.preload = false;
            });
        }
        onMounted(function () {
            store.commit("checkReadGuideScreen", Constant.GUIDE_USER_ACTI);
            props.breadcrumb[2] = { name: "アクティビティ", route: "" };
            getUserActivities();
            var user = CommonService.getCookie("user");
            var user_infor = {};
            if (user) {
                user_infor = JSON.parse(decodeURIComponent(user));
                state.isOwner = user_infor.account_id == Number(route.params.userId);
            }
        });
        return {
            state: state,
            config: config,
            openEditModal: openEditModal,
            updateYoutubeEmbed: updateYoutubeEmbed,
            removeYoutubeEmbed: removeYoutubeEmbed,
            closeEditModal: closeEditModal,
            getTypeIconClass: getTypeIconClass,
            formatDate: formatDate
        };
    }
});
