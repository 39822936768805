import LanguageUtil from "@/commons/LanguageUtil";
import ObjectUtil from "@/commons/ObjectUtil";
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Field, Form } from "vee-validate";
import * as yup from "yup";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import UserInteractor from "@/domain/usecases/UserInteractor";
import DIContainer from "@/core/DIContainer";
import * as agh from "agh.sprintf";
import Constant from "@/commons/Constant";
export default defineComponent({
    name: "UserProfileEditActivityPopup",
    components: {
        Field: Field,
        Form: Form,
        ErrorMessage: ErrorMessage
    },
    props: [
        "field",
        "youtubeCodeUpdate"
    ],
    setup: function (props, _a) {
        var emit = _a.emit;
        var store = useStore();
        var router = useRouter();
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            field: props.field,
            youtubeCode: props.youtubeCodeUpdate,
            errorUpdateUserProfileActivityMessage: null,
            errorValidateMessage: {},
            userProfileActivities: ObjectUtil.copyObject(store.state.userProfileActivities),
            userProfileInfo: store.state.userProfileInfo,
        });
        var userInteractor = DIContainer.instance.get(UserInteractor);
        var schema = yup.object().shape({
            github: yup
                .string()
                .nullable()
                .matches(Constant.GITHUB_URL_PATTERN, {
                message: state.aLang.validation_format_github_url,
                excludeEmptyString: true
            })
                .max(1000, agh.sprintf(state.aLang.validation_max_length, 1000))
                .transform(function (value, originalValue) {
                return (originalValue && originalValue.trim() === "") ? "" : value;
            }),
            qiita: yup
                .string()
                .nullable()
                .matches(Constant.QIITA_URL_PATTERN, {
                message: state.aLang.validation_format_qiita_url,
                excludeEmptyString: true
            })
                .max(1000, agh.sprintf(state.aLang.validation_max_length, 1000))
                .transform(function (value, originalValue) {
                return (originalValue && originalValue.trim() === "") ? "" : value;
            }),
            blog: yup
                .string()
                .nullable()
                .matches(Constant.URL_PATTERN, {
                message: state.aLang.validation_format_url,
                excludeEmptyString: true
            })
                .max(1000, agh.sprintf(state.aLang.validation_max_length, 1000))
                .transform(function (value, originalValue) {
                return (originalValue && originalValue.trim() === "") ? "" : value;
            }),
            youtube: yup
                .string()
                .nullable()
                .matches(Constant.YOUTUBE_URL_PATTERN, {
                message: state.aLang.validation_format_youtube_url,
                excludeEmptyString: true
            })
                .max(50, agh.sprintf(state.aLang.validation_max_length, 50))
                .transform(function (value, originalValue) {
                return (originalValue && originalValue.trim() === "") ? "" : value;
            }),
        });
        function onSubmit(formData) {
            if (props.field === 'youtube') {
                if (props.youtubeCodeUpdate) {
                    var idx = state.userProfileActivities.youtube.indexOf(props.youtubeCodeUpdate);
                    if (idx !== -1) {
                        state.userProfileActivities.youtube[idx] = formData.youtube;
                    }
                    else {
                        state.userProfileActivities.youtube.push(formData.youtube);
                    }
                }
                else {
                    state.userProfileActivities.youtube.push(formData.youtube);
                }
                state.userProfileActivities.youtube = state.userProfileActivities.youtube.filter(function (x) { return x !== ""; });
                formData.youtube = state.userProfileActivities.youtube;
            }
            return userInteractor
                .updateUserProfileActivities(formData)
                .then(function (result) {
                emit('close');
            }, function (error) {
                if (error.validation_errors) {
                    state.errorValidateMessage = error.validation_errors;
                }
                state.errorUpdateUserProfileActivityMessage = error.message;
            });
        }
        return {
            state: state,
            schema: schema,
            onSubmit: onSubmit
        };
    }
});
