<template id="modal-template">
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="modal-body">
                        <slot name="body">
                            <div class="profile_activity__edit__container">
                                <div class="profile_activity__edit__container-title">
                                    編集中
                                </div>
                                <Form
                                    class="horizontal"
                                    @submit="onSubmit"
                                    v-slot="{ errors, isSubmitting }"
                                    :validation-schema="schema"
                                >
                                    <div class="form-ctrl" v-if="state.field == 'github'">
                                        <label for="github" class="text">Github</label>
                                        <div class="form-group">
                                            <Field
                                                maxlength="1000"
                                                id="github"
                                                type="text"
                                                name="github"
                                                as="input"
                                                placeholder="https://github.com/(User ID) /"
                                                :value="state.userProfileActivities.github"
                                            />
                                            <ErrorMessage
                                                v-if="errors.github"
                                                :message="errors.github"
                                            />
                                            <template
                                                v-if="state.errorValidateMessage.github"
                                            >
                                                <ErrorMessage
                                                    v-for="m of state.errorValidateMessage.github"
                                                    :key="m"
                                                    :message="m"
                                                >
                                                </ErrorMessage>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="form-ctrl" v-if="state.field == 'qiita'">
                                        <label for="qiita" class="text">Qiita</label>
                                        <div class="form-group">
                                            <Field
                                                maxlength="1000"
                                                id="qiita"
                                                type="text"
                                                name="qiita"
                                                as="input"
                                                placeholder="https://qiita.com/(User ID) /"
                                                :value="state.userProfileActivities.qiita"
                                            />
                                            <ErrorMessage
                                                v-if="errors.qiita"
                                                :message="errors.qiita"
                                            />
                                            <template
                                                v-if="state.errorValidateMessage.qiita"
                                            >
                                                <ErrorMessage
                                                    v-for="m of state.errorValidateMessage.qiita"
                                                    :key="m"
                                                    :message="m"
                                                >
                                                </ErrorMessage>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="form-ctrl" v-if="state.field == 'blog'">
                                        <label for="blog" class="text">Blog</label>
                                        <div class="form-group">
                                            <Field
                                                maxlength="1000"
                                                id="blog"
                                                type="text"
                                                name="blog"
                                                as="input"
                                                placeholder="RSSのURLを指定してください　例：https://blog_url.com/rss/"
                                                :value="state.userProfileActivities.feed_url"
                                            />
                                            <ErrorMessage
                                                v-if="errors.blog"
                                                :message="errors.blog"
                                            />
                                            <template
                                                v-if="state.errorValidateMessage.blog"
                                            >
                                                <ErrorMessage
                                                    v-for="m of state.errorValidateMessage.blog"
                                                    :key="m"
                                                    :message="m"
                                                >
                                                </ErrorMessage>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="form-ctrl" v-if="state.field == 'youtube'">
                                        <label for="youtube" class="text">Youtube</label>
                                        <div class="form-group">
                                            <Field
                                                maxlength="50"
                                                id="youtube"
                                                type="text"
                                                name="youtube"
                                                as="input"
                                                :placeholder="state.aLang.youtube_input_placeholder"
                                                :value="state.youtubeCode"
                                            />
                                            <ErrorMessage
                                                v-if="errors.youtube"
                                                :message="errors.youtube"
                                            />
                                            <template
                                                v-if="state.errorValidateMessage.youtube"
                                            >
                                                <ErrorMessage
                                                    v-for="m of state.errorValidateMessage.youtube"
                                                    :key="m"
                                                    :message="m"
                                                >
                                                </ErrorMessage>
                                            </template>
                                        </div>
                                    </div>

                                    <ErrorMessage
                                        v-if="
                                            state.errorUpdateUserProfileActivityMessage
                                        "
                                        :message="
                                            state.errorUpdateUserProfileActivityMessage
                                        "
                                    />

                                    <div
                                        class="profile_activity__edit__container-button"
                                    >
                                        <button
                                            @click="$emit('close')"
                                            type="button"
                                            class="btn-light-gray btn-md sweep-to-top"
                                        >
                                            キャンセル
                                        </button>
                                        <button
                                            :disabled="isSubmitting"
                                            :class="{
                                                submitting: isSubmitting
                                            }"
                                            type="submit"
                                            class="btn-blue btn-md sweep-to-top"
                                        >
                                            <i
                                                v-if="isSubmitting"
                                                class="fas fa-circle-notch fa-spin"
                                            ></i>
                                            編集完了
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script lang="ts" src="@/presentation/components/modals/user-profile-edit-activities/UserProfileEditActivities.ts" />
<link type="scss" scoped src="@/presentation/components/modals/user-profile-edit-activities/UserProfileEditActivities.scss" />
